import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import InputField from "./CustomInputField"
import SelectField from "./CustomSelectField"
import Checkbox from "./CustomCheckbox"
import axios from "axios"

const QuoteForm = props => {
  //const globalId = btoa(`GravityFormsForm:3`) // Results in "R3Jhdml0eUZvcm1zRm9ybToz"
  const data = useStaticQuery(graphql`
    query {
      wp {
        gravityFormsForm(id: "R3Jhdml0eUZvcm1zRm9ybToz") {
          fields {
            nodes {
              ... on WP_SelectField {
                id
                isRequired
                label
                type
                choices {
                  isSelected
                  text
                  value
                }
              }
              ... on WP_TextField {
                id
                isRequired
                label
                type
              }
              ... on WP_PhoneField {
                id
                isRequired
                inputName
                label
                type
              }
              ... on WP_EmailField {
                id
                isRequired
                label
                type
              }
            }
          }
        }
        products(
          first: 9999
          where: { orderby: { field: TITLE, order: DESC } }
        ) {
          nodes {
            title
            databaseId
            featuredImage {
              node {
                sourceUrl
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 50, maxHeight: 50) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { productsToShow, activeProduct } = props
  const [computedProducts, setComputedProducts] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isOffline, setIsOffline] = useState(false)

  const handleOnline = () => setIsOffline(false)
  const handleOffline = () => setIsOffline(true)

  useEffect(() => {
    if (navigator.onLine) {
      setIsOffline(false)
    } else {
      setIsOffline(true)
    }

    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  })

  // Component mount
  useEffect(() => {
    // Product checkbox setup
    let allProducts = computedProducts
    if (productsToShow === "all") {
      // If all products, set product list to query results...
      allProducts = data.wp.products.nodes
    } else {
      // Otherwise, set it to the products passed as props
      allProducts = productsToShow || null
    }

    // Exit if no products
    if (!allProducts) return;

    if (activeProduct) {
      // Reorder products, so if the activeProduct is first in the list (single product page)
      let reorderedProducts = allProducts.filter(
        item => item.databaseId !== activeProduct.databaseId
      )
      allProducts = [activeProduct, ...reorderedProducts]
    }

    // Add a "checked" property to each item and set initially checked if applicable
    allProducts.map(
      p =>
        (p.checked = p.databaseId === activeProduct?.databaseId ? true : false)
    )

    // Update state
    setComputedProducts(allProducts)
  }, [])

  // Checkbox event listener
  const handleCheck = e => {
    let updatedProducts = computedProducts.map(p => {
      if (p.title === e.target.value) {
        p.checked = e.target.checked
      }
      return p
    })

    setComputedProducts(updatedProducts)
  }

  // Form submit
  const handleSubmit = async e => {
    e.preventDefault()

    const formData = new FormData(e.target)

    // Convert FormData into JSON
    var dataObj = {
      form_id: "3",
      baseUrl: "https://osmocoteapp.revhub.io/wp-json/gf/v2",
    }

    formData.forEach(function (value, key) {
      dataObj[key] = value
    })
    var jsonData = JSON.stringify(dataObj)

    try {
      setIsError(false)
      setIsLoading(true)
      const result = await axios({
        method: "POST",
        url: "/.netlify/functions/newGfEntry",
        data: jsonData,
      })
        .then(function (response) {
          setIsLoading(false)
          setIsSuccess(true)
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "tba.form.confirmation",
            eventData: {
              formTitle: "Request a Quote",
              formLocation: window.location.href,
            },
          })
        })
        .catch(function (error) {
          setIsLoading(false)
          setIsSuccess(false)
          setIsError(true)
        })
    } catch (error) {
      setIsLoading(false)
      setIsError(true)
    }
  }

  return (
    <div>
      {isOffline && (
        <div className="alert alert-warning form-error">
          <strong>Your device is offline</strong> <br />
          Please connect to a network to submit this form.
        </div>
      )}
      {isError && (
        <div className="alert alert-danger form-error">
          Sorry, an unexpected error occurred. Please try again.
        </div>
      )}
      {isSuccess ? (
        <div className="alert alert-success form-success">
          Thanks for requesting a quote! We will be in touch soon.
        </div>
      ) : (
        <form
          onSubmit={e => handleSubmit(e)}
          className={`${isLoading && "form-loading"}`}
        >
          {data.wp.gravityFormsForm.fields.nodes.map((field, i) => {
            if (field.type === "select") {
              return (
                <SelectField
                  name={`input_${field.id}`}
                  label={field.label}
                  key={field.type + field.id}
                >
                  {field.choices.map((option, i) => (
                    <option
                      value={option.value}
                      key={`${field.type}-${field.id}-${i}`}
                    >
                      {option.text}
                    </option>
                  ))}
                </SelectField>
              )
            } else if (
              field.type === "text" &&
              field.id === 7 &&
              data.wp.products.nodes
            ) {
              // Product Field
              return (
                <div className="text-left" key={i}>
                  <label className="mb-2 font-weight-bold text-secondary">
                    Product(s)
                  </label>
                  <div className="overflow-y-container mb-3">
                    {computedProducts.map((option, i) => (
                      <Checkbox
                        option={option}
                        onCheckboxChange={handleCheck}
                        key={`${option.databaseId}`}
                        checked={option.checked}
                      />
                    ))}
                  </div>

                  <input
                    name={`input_${field.id}`}
                    type={field.type}
                    label={field.label}
                    required={field.isRequired}
                    key={field.type + field.id}
                    value={computedProducts
                      .filter(p => p.checked === true)
                      .map(q => q.title)
                      .join(", ")}
                    className="form-control my-3 d-none"
                    readOnly
                  />
                </div>
              )
            } else {
              return (
                <InputField
                  name={`input_${field.id}`}
                  type={field.type}
                  label={field.label}
                  required={field.isRequired}
                  key={field.type + field.id}
                />
              )
            }
          })}
          <button className="btn btn-icl btn-block btn-skew" type="submit">
            <span>Submit</span>
          </button>

          {isLoading && (
            <div className="loading-rings">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </form>
      )}
    </div>
  )
}

export default QuoteForm